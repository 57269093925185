<template>
	<div class="">
		<div class="tabs row">
			<div class="col-auto col-md-2 col-12">
				<ul role="tablist" class="nav nav-pills flex-column nav-left">
					<li v-if="haveAccess" role="presentation" class="nav-item">
						<router-link exact :to="{ name: 'grm' }" class="nav-link">Dashboard</router-link>
					</li>
					<li role="presentation" class="nav-item">
						<router-link :to="{ name: 'grm.complaints' }" class="nav-link">Complaints</router-link>
					</li>
					<li v-if="haveAccess" role="presentation" class="nav-item">
						<router-link :to="{ name: 'grm.reports' }" class="nav-link">Reports</router-link>
					</li>
					<li v-if="haveAccess" role="presentation" class="nav-item">
						<router-link :to="{ name: 'grm.settings' }" class="nav-link">Settings</router-link>
					</li>
				</ul>
			</div>
			<div class="tab-content col col-12 col-md-10 mt-1 mt-md-0">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'GRM',

	computed: {
		...mapState('auth', ['user']),

		haveAccess() {
			return [10, 4].includes(this.user.type);
		},
	},
};
</script>

<style lang="scss" scoped>
.nav-pills .router-link-active {
	border-color: #8bc53f;
	background-color: #8bc53f;
	box-shadow: 0 4px 18px -4px rgb(139 197 63 / 65%);
	color: #fff;
}
</style>
